import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../components/form/Button";
import QuantityField from "../components/form/QuantityField";
import Image from "../components/elements/Image";
import { useDispatch, useSelector } from "react-redux";
import { getCartData } from "../redux/slice/cartSlice";
import { changeCartQuantity } from "../services/cartService";
import CartDeleteModal from "../components/elements/CartDeleteModal";
import { setCartDetails } from "../redux/slice/cartTotalSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    apiEndpoint: null,
    method: undefined,
    targetId: null,
    data: {},
    payload: {
      body: {
        //body or queries
        ids: null,
      },
    },
  });

  const { data: cartLists, isLoaded: cartLoaded } = useSelector(
    (state) => state?.cart?.cartData
  );

  const cartTableData = cartLists?.map((item) => {
    const product = item?.product;
    const wearType = item?.product?.wearType;
    const variant = product?.variant;
    const type = item?.type;
    const deliveryMethod = item?.deliveryMethod;
    const deliveryDetail = item?.deliveryDetail;

    // combo
    const combo = item?.combo?.map((elm) => ({
      name: elm?.name,
      image: elm?.cover?.url,
    }));

    // Extract necessary information
    const id = item?._id;
    const isFreeEligible = item?.isFreeEligible;
    const name = product?.name;
    const brand = product?.brand?.name;
    const attributes = variant?.attributes;
    const price = variant?.salePrice;
    const imageSrc = variant?.cover?.url;
    const shipping = "Free";
    const quantity = item?.quantity;

    // Create and return an array containing the formatted item
    return {
      id,
      name,
      type,
      price,
      combo,
      brand,
      imageSrc,
      shipping,
      quantity,
      wearType,
      attributes,
      deliveryMethod,
      deliveryDetail,
      isFreeEligible,
    };
  });

  const cartCalculationData = useSelector(
    (state) => state?.cartTotal?.cartListTotal
  );

  const cartQuentityHandler = ({ cart, index, quantity }) => {
    changeCartQuantity({
      id: cart?.id,
      quantity,
    }).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.message);
        dispatch(
          getCartData(
            cartLists?.map((item) =>
              item?._id == cart?.id ? { ...item, quantity: quantity } : item
            )
          )
        );
        dispatch(setCartDetails(res?.cartSummary));
      } else {
        toast.error(res?.error);
      }
    });
  };

  const getDeleteResponse = async (res) => {
    if (res?.status === 200) {
      dispatch(
        getCartData(
          cartLists?.filter((item) => item?._id !== res?.data?.data?.id)
        )
      );
      dispatch(setCartDetails(res?.data?.cartSummary));
      setDeleteModal({ isOpen: false });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };
  return (
    <div className="relative py-5">
      {!cartLoaded && (
        <div className="w-full pl-36">
          <SkeletonLoader />
        </div>
      )}
      {cartLoaded && cartLists && cartLists?.length !== 0 && (
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="grid grid-cols-12 gap-4 sm:gap-7 lg:gap-8 xl:gap-10">
            <div className="col-span-12 xl:col-span-8">
              <div className="w-full bg-white rounded-lg overflow-hidden divide-y divide-slate-200">
                <div className="hidden lg:flex items-center justify-between py-5 px-10">
                  <h3 className=" text-left text-black font-bold text-sm lg:text-sm uppercase lg:w-[39%] xl:w-[60%] 2xl:w-[50%]">
                    Product
                  </h3>
                  <h3 className="  text-black font-bold text-sm lg:text-sm uppercase text-center w-1/5">
                    Quantity
                  </h3>
                  <h3 className="  text-black font-bold text-sm lg:text-sm uppercase text-center w-[10%]">
                    subtotal
                  </h3>
                  <h3 className="  text-black font-bold text-sm lg:text-sm uppercase text-center w-[10%]"></h3>
                </div>

                {cartTableData.map((cart, index) => (
                  <div key={index.toString()}>
                    <div
                      key={cart.id}
                      className="hover:bg-gray-100 px-3 sm:px-7 py-3 sm:py-5 divide-y divide-slate-300 overflow-hidden"
                    >
                      <div className="relative flex lg:items-center space-x-4">
                        <div className="w-20 md:w-44 h-20 md:h-44 overflow-hidden relative z-[0] flex-shrink-1 md:flex-shrink-0 rounded-lg">
                          <Image
                            className="h-full w-full object-contain md:object-cover object-top"
                            fill
                            src={cart.imageSrc}
                            alt={cart.imageSrc}
                          />
                        </div>
                        <div className="relative flex-col lg:flex-row flex lg:items-center justify-center lg:justify-between w-full">
                          <div className=" space-y-1">
                            <div className="text-sm font-bold text-black line-clamp-1 !leading-none mb-2">
                              {cart?.name?.substring(0, 20)}...
                            </div>
                            <div className="">
                              <div className="">
                                <span className={``}>Rs. {cart?.price}</span>
                              </div>
                            </div>
                            {/* {cart?.brand && (
                              <div className="capitalize text-slate-500 lg:text-xs 2xl:text-sm">
                                <span className="font-normal text-slate-500 text-sm">
                                  Brand :
                                </span>{" "}
                                {cart?.brand}
                              </div>
                            )}
                            {cart?.wearType && (
                              <div className="capitalize text-slate-500 lg:text-xs 2xl:text-sm">
                                <span className="font-normal">Wear Type :</span>{" "}
                                {cart?.wearType}
                              </div>
                            )} */}

                            {cart.attributes &&
                              cart.attributes.length > 0 &&
                              cart.attributes[0].attribute && (
                                <div className="flex flex-col justify-between mt-1 lg:mt-0 flex-grow space-y-0 flex-shrink">
                                  {cart?.attributes?.map((attribute) => (
                                    <span
                                      key={attribute?._id}
                                      className="capitalize text-slate-500 lg:text-xs 2xl:text-sm"
                                    >
                                      <span className="font-normal">
                                        {attribute?.attribute?.name}
                                      </span>{" "}
                                      {attribute?.items?.name && ":"}{" "}
                                      <span className="capitalize">
                                        {attribute?.items?.name}
                                      </span>
                                    </span>
                                  ))}
                                </div>
                              )}
                          </div>

                          <div className="flex items-center space-x-2 justify-between lg:justify-center w-full lg:w-1/5">
                            <div className="font-bold text-sm uppercase block lg:hidden">
                              quantity:
                            </div>
                            <div className="relative">
                              <QuantityField
                                value={cart?.quantity}
                                labelClasses={"!font-bold !text-lg"}
                                buttonClasses={
                                  "!text-black border-t border-b border-r !rounded-0 !w-full md:!w-full lg:!w-full !h-8 lg:!h-7 !min-w-6 sm:!min-w-8 lg:!min-w-8 text-sm"
                                }
                                divClass={" gap-0 rounded-lg"}
                                buttonIconPosition={"left"}
                                buttonIcon={"fa-regular fa-plus"}
                                inputClasses={
                                  "relative z-[1] w-10 h-16 md:h-16 lg:h-14 sm:min-w-8 md:min-w-20 lg:min-w-20 rounded-l-md !text-black border ml-1 border-slate-300 bg-slate-100"
                                }
                                onChange={(val) => {
                                  cartQuentityHandler({
                                    cart,
                                    index,
                                    quantity: val,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="text-left lg:text-center w-full lg:w-[10%] font-semibold text-sm text-black flex items-center justify-between lg:justify-center space-x-2 py-2 lg:py-0">
                            <div>Rs.{cart?.quantity * cart?.price}</div>
                          </div>
                          <div className="relative flex items-center justify-between lg:justify-center w-full lg:w-[10%] space-x-2">
                            <div className="font-bold text-sm uppercase block lg:hidden"></div>
                            <Button
                              buttonFunction={() =>
                                setDeleteModal({
                                  isOpen: true,
                                  apiEndpoint: "cart/remove-cart-item",
                                  method: "patch",
                                  data: cart,
                                  payload: {
                                    body: {
                                      id: cart?.id,
                                    },
                                  },
                                })
                              }
                              buttonIconPosition={"left"}
                              buttonIcon={"fa-regular fa-trash-can"}
                              buttonLabelClasses={""}
                              buttonClasses={
                                "!bg-transparent !border-transparent !text-sv-blue !px-0"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Check - out */}
              <div className="relative px-5 lg:px-10 py-5 lg:pt-0 lg:pb-0 bg-white">
                <div className="border border-slate-200 w-full"></div>
                <div className="w-full pt-5 px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
                  {/* Continue shopping */}
                  <div className="relative flex justify-center lg:justify-end order-2 lg:order-1">
                    <Button
                      buttonLabel={"continue shopping"}
                      buttonIcon={"fa-regular fa-bag-shopping"}
                      buttonIconPosition={"left"}
                      buttonLabelClasses={"capitalize !font-normal"}
                      buttonClasses={
                        "!bg-sv-blue rounded-md border-none flex-shrink-0 px-4 items-center justify-center hover:!bg-black transition-all duration-300"
                      }
                      buttonHasLink={"true"}
                      buttonLink={"/products"}
                    />
                  </div>
                  {/* checkout section */}
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-12 xl:col-span-4 relative">
              <div className="relative px-5 lg:px-7 py-5 lg:py-10 bg-slate-50 md:sticky top-5">
                <h3 className="font-semibold text-black text-base md:text-2xl capitalize">
                  Cart totals
                </h3>
                <div className="border border-slate-200 w-full"></div>
                <div className="w-full py-10 px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
                  {/* checkout section */}
                  <div className="relative w-full space-y-5 order-1 lg:order-2">
                    <div className="max-w-sm w-full mx-auto space-y-2">
                      <div className="flex gap-10 items-center">
                        <div className="font-bold text-left text-slate-700 text-base xl:text-lg capitalize w-1/2 ">
                          Subtotal <br></br>
                          <span className="text-sm text-slate-500 font-medium">
                            ({cartCalculationData?.totalCartItem || 0}
                            {cartCalculationData?.totalCartItem === 1
                              ? " item"
                              : " items"}
                            )
                          </span>
                        </div>
                        <div className="font-bold text-slate-700 text-base xl:text-lg capitalize w-1/2 text-end">
                          Rs.{cartCalculationData?.totalCartValue || 0}
                        </div>
                      </div>
                      <div className="border border-slate-200 w-full"></div>
                    </div>
                    <div className="relative max-w-sm w-full mx-auto">
                      <Button
                        buttonLabel={"Proceed To Checkout"}
                        buttonLabelClasses={"capitalize !font-normal"}
                        buttonIcon={"fa-sharp fa-solid fa-lock"}
                        buttonIconPosition={"left"}
                        buttonClasses={
                          "!bg-sv-blue w-full rounded-lg flex-shrink-0 px-6 items-center justify-center !border-0 hover:!bg-black transition-all duration-300"
                        }
                        buttonHasLink={true}
                        buttonLink={"/checkout"}
                      />
                    </div>
                    <div className="text-sm font-bold text-center text-blue-600 ">
                      <i className="fa-regular font-bold fa-fw fa-sack-dollar text-sm mr-1"></i>
                      Taxes and shipping calculated at checkout
                    </div>
                    <CartDeleteModal
                      open={deleteModal?.isOpen}
                      data={{
                        apiEndpoint: deleteModal?.apiEndpoint,
                        method: deleteModal?.method,
                        data: deleteModal?.data,
                        payload: deleteModal?.payload,
                      }}
                      onClose={() => setDeleteModal({ isOpen: false })}
                      getResponse={getDeleteResponse}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {cartLoaded && cartLists?.length === 0 && (
        <>
          <div className="flex flex-col items-center space-y-8">
            <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center ">
              <div className="text-[#28A642] text-6xl">
                <i className="fa-solid fa-cart-xmark"></i>
              </div>
            </div>
            <div className="text-cape-cod text-3xl">Your Cart is empty.</div>
            <div className="text-concord text-base max-w-md text-center">
              You don&apos;t have any products in the cart yet. Explore our wide
              range of products and start shopping!
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="animate-pulse flex space-x-4 w-full h-full">
      <div className="flex-1 space-y-4 py-4 max-w-5xl">
        <div className="h-5 bg-gray-300 rounded w-3/4"></div>
        <div className="space-y-2">
          <div className="h-5 bg-gray-300 rounded  w-3/4"></div>
          <div className="h-5 bg-gray-300 rounded w-4/6"></div>
          <div className="h-5 bg-gray-300 rounded w-3/6"></div>
          <div className="h-5 bg-gray-300 rounded w-4/6"></div>
          <div className="h-5 bg-gray-300 rounded w-3/6"></div>
          <div className="h-5 bg-gray-300 rounded w-4/6"></div>
          <div className="h-5 bg-gray-300 rounded w-5/6"></div>
          <div className="h-5 bg-gray-300 rounded w-3/6"></div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
