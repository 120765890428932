import React, { useCallback, useEffect, useState } from "react";
import Input from "../../components/form/Input";
import Checkbox from "../../components/form/Checkbox";
import Button from "../../components/form/Button";
import useForm from "../../hooks/useForm";
import { getProfileDetails } from "../../services/profilrService";
import OrderMenu from "./OrderMenu";
import { addAddress, getAddress } from "../../services/userService";
import { formValidate } from "../../helpers/formValidate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CartDeleteModal from "../../components/elements/CartDeleteModal";

const MyAddress = () => {
  const navigate = useNavigate();
  const validation = {
    address1: { required: true, message: "address is required" },
    address2: { required: true, message: "address2 is required" },
    state: { required: true, message: "State is required" },
    city: { required: true, message: "City is required" },
    zipcode: { required: true, message: "Zip code number is required" },
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const [loaded, setLoaded] = useState(true);

  const [addressData, setAddressData] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    apiEndpoint: null,
    method: undefined,
    targetId: null,
    data: {},
    payload: {
      body: {
        //body or queries
        ids: null,
      },
    },
  });

  const [data, setData] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [addressDetails, setaddressDetails] = useState({});

  useEffect(() => {
    getProfileDetails().then((res) => {
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  const loadList = useCallback(() => {
    getAddress().then((res) => {
      if (res && res?.status === 200) {
        setAddressData(res?.docs);
      } else {
        setAddressData([]);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    if (addressDetails?._id) {
      setFieldsValue({
        address1: addressDetails?.address1,
        address2: addressDetails?.address2,
        zipcode: Number(addressDetails?.zipcode),
        state: addressDetails?.state,
        city: addressDetails?.city,
        isDefault: isDefault,
      });
    }
  }, [addressDetails?._id]);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      const res = await addAddress({
        addressId: addressDetails?._id,
        address: {
          address1: values?.address1,
          address2: values?.address2,
          zipcode: Number(values?.zipcode),
          state: values?.state,
          city: values?.city,
          isDefault: isDefault,
        },
      });
      if (res?.status === 200) {
        toast.info(res?.message);
        loadList();
        resetField({
          address1: "",
          address2: "",
          zipcode: "",
          state: "",
          city: "",
        });
        setIsDefault(false);
        setaddressDetails({});
      } else if (res?.status === 400) {
        toast.info(res?.message || res?.errors || "Something went wrong!!!");
      } else {
        toast.info(res?.message || res?.errors || "Something went wrong!!!");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
    } finally {
      setLoaded(true);
    }
  };

  const getDeleteResponse = async (res) => {
    if (res?.status === 200) {
      setDeleteModal({ isOpen: false });
      toast.success(res?.message);
      setAddressData(res?.data?.docs);
    } else {
      toast.error(res?.message);
    }
  };

  console.log(addressDetails, "addressDetailsaddressDetails");

  return (
    <div>
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={data?.name} />
            </div>
            <div className="w-full relative bg-white p-10">
              <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[82%] after:z-10">
                My Address
              </div>
              <div className="flex-col sm:flex-row flex">
                {addressData &&
                  Array.isArray(addressData) &&
                  addressData?.length > 0 &&
                  addressData?.map((item, index) => (
                    <div
                      className="relative bg-[#f6f6f6] rounded-xl py-2 px-4 space-y-3 mr-0 sm:mr-6 mb-4 sm:mb-0"
                      key={index}
                    >
                      <div className="text-cape-cod text-xl font-medium border-b border-slate-200 pb-1">
                        {item?.city}
                      </div>
                      <div className="text-cape-cod text-sm xl:leading-3">
                        {item?.address1}, {item?.address2}, {item?.city}
                      </div>
                      <div className="text-cape-cod text-sm leading-3">
                        {item?.zipcode}
                      </div>
                      <div className="flex items-center !mt-0 absolute top-[9px] right-3">
                        {item?.isDefault && (
                          <div className="flex items-center justify-center capitalize px-3 py-1 bg-slate-300 rounded-full text-black text-xs font-medium">
                            default
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-2 ">
                        <div className="relative">
                          <Button
                            buttonLabel={"remove"}
                            buttonLabelClasses={
                              "!capitalize !text-white !text-xs !font-normal group-hover/button:!text-white !ml-1"
                            }
                            buttonClasses={
                              "!px-3 !border-none text !border-org-theme !rounded-full !py-1 !h-7 group/button hover:!bg-black transition-all duration-300"
                            }
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonFunction={() =>
                              setDeleteModal({
                                isOpen: true,
                                apiEndpoint: "delete-address",
                                method: "patch",
                                data: item,
                                payload: {
                                  body: {
                                    addressId: item?._id,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                        <div className="relative">
                          <Button
                            buttonLabel={"edit"}
                            buttonLabelClasses={
                              "!capitalize !text-white !text-sm !font-normal group-hover/button:!text-white !ml-1"
                            }
                            buttonClasses={
                              "!px-3 !border-none text !border-org-theme !rounded-full !py-1 !h-7 !text-xs  group/button hover:!bg-black transition-all duration-300"
                            }
                            buttonIcon={"fa-solid fa-pen"}
                            buttonIconPosition={"left"}
                            buttonHasLink={false}
                            buttonFunction={() => {
                              setaddressDetails(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {addressDetails?._id ? (
                <>
                  <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 my-10 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[82%] after:z-10">
                    Edit address
                  </div>

                  <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
                    <div className="grid grid-cols-12 sm:grid-cols-6 gap-x-1 sm:gap-x-10 gap-y-5">
                      <div className="relative col-span-12 sm:col-span-3">
                        <Input
                          label={"Street Address"}
                          inputType={"text"}
                          labelClasses={"!font-normal capitalize !text-base"}
                          inputClasses={
                            "h-12 !border-none !bg-gray-100 !font-normal "
                          }
                          inputPlaceholder="Enter Street Address"
                          inputName={"address1"}
                          value={values?.address1}
                          onChange={handleChange}
                          {...formValidate(errors, "address1")}
                        />
                      </div>
                      <div className="relative col-span-12 sm:col-span-3">
                        <Input
                          label={"Apt, suite, unit"}
                          labelClasses={"!font-normal capitalize !text-base"}
                          inputClasses={
                            "h-12 !border-none !bg-gray-100 !font-normal "
                          }
                          inputPlaceholder="apartment, suite, unit, etc. (optional)"
                          inputName={"address2"}
                          value={values?.address2}
                          onChange={handleChange}
                          {...formValidate(errors, "address2")}
                        />
                      </div>
                      <div className="relative col-span-12 sm:col-span-3">
                        <Input
                          label={"Town / City"}
                          labelClasses={"!font-normal capitalize !text-base"}
                          inputClasses={
                            "h-12 !border-none !bg-gray-100 !font-normal "
                          }
                          inputPlaceholder="Town / City"
                          inputName={"city"}
                          value={values?.city}
                          onChange={handleChange}
                          {...formValidate(errors, "city")}
                        />
                      </div>
                      <div className="relative col-span-12 sm:col-span-3">
                        <Input
                          label={"State"}
                          labelClasses={"!font-normal capitalize !text-base"}
                          inputClasses={
                            "h-12 !border-none !bg-gray-100 !font-normal "
                          }
                          inputPlaceholder="State"
                          inputName={"state"}
                          value={values?.state}
                          onChange={handleChange}
                          {...formValidate(errors, "state")}
                        />
                      </div>
                      <div className="relative col-span-12 sm:col-span-3">
                        <Input
                          label={"Postal Code"}
                          labelClasses={"!font-normal capitalize !text-base"}
                          inputClasses={
                            "h-12 !border-none !bg-gray-100 !font-regular "
                          }
                          inputPlaceholder="Postal Code"
                          inputType={"text"}
                          inputName={"zipcode"}
                          value={values?.zipcode}
                          onChange={handleChange}
                          {...formValidate(errors, "zipcode")}
                        />
                      </div>
                      <div className="relative col-span-12 sm:col-span-6">
                        <Checkbox
                          checkboxLabel={"Set as default address"}
                          checkboxLableClass={"!text-lg !font-normal"}
                          value={isDefault}
                          onChange={(e) => setIsDefault(e)}
                        />
                      </div>

                      <div className="relative col-span-12 sm:col-span-6">
                        <div className="flex items-center gap-5">
                          <Button
                            buttonLabel={"save"}
                            buttonLabelClasses={
                              "!text-lg !font-normal !capitalize"
                            }
                            buttonClasses={
                              "!border-org-theme !h-12 !px-4 !border-0 hover:!bg-black transition-all duration-300"
                            }
                            buttonType={"submit"}
                          />
                          <Button
                            buttonLabel={"cancel"}
                            buttonHasLink={false}
                            buttonType={"button"}
                            buttonLabelClasses={
                              "!text-lg !font-bold !capitalize !text-concord group-hover/button:!text-white"
                            }
                            buttonClasses={
                              "!bg-slate-200 !border-slate-200 !h-12 !px-4 group/button hover:!bg-black transition-all duration-300"
                            }
                            buttonFunction={() => {
                              setaddressDetails({});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                addressData.length < 2 && (
                  <>
                    <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 my-10 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[82%] after:z-10">
                      Add address
                    </div>

                    <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
                      <div className="grid grid-cols-12 sm:grid-cols-6 gap-x-1 sm:gap-x-10 gap-y-5">
                        <div className="relative col-span-12 sm:col-span-3">
                          <Input
                            label={"Street Address"}
                            inputType={"text"}
                            labelClasses={"!font-normal capitalize !text-base"}
                            inputClasses={
                              "h-12 !border-none !bg-gray-100 !font-normal "
                            }
                            inputPlaceholder="Enter Street Address"
                            inputName={"address1"}
                            value={values?.address1}
                            onChange={handleChange}
                            {...formValidate(errors, "address1")}
                          />
                        </div>
                        <div className="relative col-span-12 sm:col-span-3">
                          <Input
                            label={"Apt, suite, unit"}
                            labelClasses={"!font-normal capitalize !text-base"}
                            inputClasses={
                              "h-12 !border-none !bg-gray-100 !font-normal "
                            }
                            inputPlaceholder="apartment, suite, unit, etc. (optional)"
                            inputName={"address2"}
                            value={values?.address2}
                            onChange={handleChange}
                            {...formValidate(errors, "address2")}
                          />
                        </div>
                        <div className="relative col-span-12 sm:col-span-3">
                          <Input
                            label={"Town / City"}
                            labelClasses={"!font-normal capitalize !text-base"}
                            inputClasses={
                              "h-12 !border-none !bg-gray-100 !font-normal "
                            }
                            inputPlaceholder="Town / City"
                            inputName={"city"}
                            value={values?.city}
                            onChange={handleChange}
                            {...formValidate(errors, "city")}
                          />
                        </div>
                        <div className="relative col-span-12 sm:col-span-3">
                          <Input
                            label={"State"}
                            labelClasses={"!font-normal capitalize !text-base"}
                            inputClasses={
                              "h-12 !border-none !bg-gray-100 !font-normal "
                            }
                            inputPlaceholder="State"
                            inputName={"state"}
                            value={values?.state}
                            onChange={handleChange}
                            {...formValidate(errors, "state")}
                          />
                        </div>
                        <div className="relative col-span-12 sm:col-span-3">
                          <Input
                            label={"Postal Code"}
                            labelClasses={"!font-normal capitalize !text-base"}
                            inputClasses={
                              "h-12 !border-none !bg-gray-100 !font-regular "
                            }
                            inputPlaceholder="Postal Code"
                            inputType={"number"}
                            inputName={"zipcode"}
                            value={values?.zipcode}
                            onChange={handleChange}
                            {...formValidate(errors, "zipcode")}
                          />
                        </div>
                        <div className="relative col-span-12 sm:col-span-6">
                          <Checkbox
                            checkboxLabel={"Set as default address"}
                            checkboxLableClass={"!text-lg !font-normal"}
                            value={isDefault}
                            onChange={(e) => setIsDefault(e)}
                          />
                        </div>

                        <div className="relative col-span-12 sm:col-span-6">
                          <div className="flex items-center gap-5">
                            <Button
                              buttonLabel={"save"}
                              buttonLabelClasses={
                                "!text-lg !font-normal !capitalize"
                              }
                              buttonClasses={
                                "!border-org-theme !h-12 !px-4 !border-0 hover:!bg-black transition-all duration-300"
                              }
                              buttonType={"submit"}
                            />
                            <Button
                              buttonLabel={"cancel"}
                              buttonHasLink={false}
                              buttonType={"button"}
                              buttonLabelClasses={
                                "!text-lg !font-bold !capitalize !text-concord group-hover/button:!text-white"
                              }
                              buttonClasses={
                                "!bg-slate-200 !border-slate-200 !h-12 !px-4 group/button hover:!bg-black transition-all duration-300"
                              }
                              buttonFunction={() => {
                                setaddressDetails({});
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <CartDeleteModal
        open={deleteModal?.isOpen}
        data={{
          apiEndpoint: deleteModal?.apiEndpoint,
          method: deleteModal?.method,
          data: deleteModal?.data,
          payload: deleteModal?.payload,
        }}
        onClose={() => setDeleteModal({ isOpen: false })}
        getResponse={getDeleteResponse}
      />
    </div>
  );
};

export default MyAddress;
