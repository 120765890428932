import React from "react";
import Button from "../form/Button";
import WishlistItem from "./WishListItem";
const List = ({ list, setlist = () => {}, loadlist }) => {
  const orderData = list;


  return (
    <div className="relative">
      {orderData?.data?.length > 0 ? (
        <div className="px-3 sm:px-5 py-3 bg-[#fafafa] rounded-lg overflow-hidden">
          {/* {orderData?.data?.map((item, index) => (
            <OrderItem key={index} data={item} activeCart={true} closeButton={true} list={list} setlist={setlist} />
          ))} */}
          {orderData?.data?.map((item, index) => (
            <WishlistItem
              key={index}
              data={item}
              closeButton={true}
              list={list}
              setlist={setlist}
              loadlist={loadlist}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center space-y-8">
            <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center ">
              <div className="text-[#BD874A] text-6xl">
                <i className="fa-regular fa-heart"></i>
              </div>
            </div>
            <div className="text-cape-cod text-3xl text-center">
              Your wishlist is empty.
            </div>
            <div className="text-concord text-base max-w-md text-center">
              You don’t have any products in the wishlist yet. Explore our wide
              range of products and start shopping
            </div>
          </div>
          <div className="flex flex-col items-center space-y-8 mt-4">
            <div className="relative flex">
              <Button
                buttonLabel={"Continue Shopping"}
                buttonClasses={"!bg-org-theme !border-none !px-10"}
                buttonLabelClasses={"!font-normal !text-sm xs:!text-lg"}
                buttonHasLink={true}
                buttonLink={"/products"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default List;
