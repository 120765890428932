import { apiRequest } from "../util/apiUtils";

export const getCartList = async (params) => {
    try {
      const res = await apiRequest("get", "cart/my-cart", { queries: params });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  export const cartListData = async (params) => {
    try {
      const res = await apiRequest("get", "cart/my-cart", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  
export const addToCartFront = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", "cart/add-to-cart", {
      body: payload,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeCartQuantity = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", "cart/change-quantity", {
      body: payload,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
