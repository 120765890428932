import { apiRequest } from "../util/apiUtils";

export const getProfileDetails = async (params) => {
  try {
    const res = await apiRequest("get", "/profile", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const profileUpdate = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      "/edit",
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changePassword = async (params) => {
  try {
    const res = await apiRequest("patch", "/change-password", {
      body: {
        ...params,
      },
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
