import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductItem from "./ProductItem";


const ProductInformation = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [isVariant, setIsVariant] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [attribute, setAttribute] = useState({});
  const [combine, setCombine] = useState(null);
  const [availableAttr, setAvailableAttr] = useState([]);

  const setUrl = useCallback(() => {
    if (data?.data?.product?.type === "variant-product") {
      let default_variant = {};
      if (
        data?.data?.product?.variant?.attributes &&
        Array.isArray(data?.data?.product?.variant?.attributes)
      ) {
        data?.data?.product?.variant?.attributes?.forEach((attr) => {
          default_variant[attr?.attribute?.name] = attr?.items?._id;
        });
      }
      setAttribute(default_variant);
    }
  }, [data]);

  useEffect(() => {
    setUrl();
  }, [setUrl]);

  useMemo(() => {
    if (data && data?.data?.product?.type === "variant-product") {
      setIsVariant(true);
      if (data?.data?.product?.variant?._id) {
        setCombine(true);
        setProductDetails(data);
      } else {
        setCombine(false);
      }
    } else {
      setIsVariant(false);
    }
  
  }, [data]);



  const redirectByAttr = (val) => {
    // console.log(val, "val");
    if (data?.data?.product?.slug) {
      const currentPathname = location.pathname;
      const currentQuery = new URLSearchParams(location.search);

      // Add new query parameters from val
      for (const key in val) {
        currentQuery.set(key, val[key]);
      }

      // Navigate to the new URL
      navigate(
        {
          pathname: currentPathname,
          search: `${currentQuery.toString()}`,
        },
        { replace: true }
      );
    }
  };


  return (
    <>
      {(combine || combine == null) && data ? (
        <ProductItem
          isVerient={isVariant}
          combine={combine}
          data={data}
          // router={router}
          attribute={attribute}
          redirectByAttr={redirectByAttr}
        />
      ) : (
        combine != null && (
          <ProductItem
            isVerient={isVariant}
            combine={combine}
            data={productDetails}
            // router={router}
            attribute={attribute}
            redirectByAttr={redirectByAttr}
          />
        )
      )}
    </>
  );
};

export default ProductInformation;
