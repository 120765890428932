import React, { useCallback, useEffect, useState } from "react";
// import { settingsDetails } from "../services/settingService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { getordersFrontList } from "../../../services/orderService"


const Order = ({ order }) => {
  const [details, setDetails] = useState({});
  // const get_details = useCallback(() => {
  //   settingsDetails().then((res) => {
  //     if (res?.status === 200) {
  //       setDetails(res?.data);
  //     } else {
  //       toast.error(res?.message);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   get_details();
  // }, [get_details]);

  return (
    <div className="relative">
      <div className="flex-col sm:flex-row flex item-start sm:items-center justify-start sm:justify-between bg-slate-50 rounded-lg py-4 px-4 sm:py-5 sm:px-10">
        <div className="space-y-1 w-full sm:w-1/2">
          <div className="text-cape-cod text-xl">Order no: {order?.orderId}</div>
          <div className="flex items-center space-x-2">
            <div className="text-concord text-sm">Order Date :</div>
            <div className="text-black text-sm">{moment(order?.date).format("DD-MM-YYYY") || "N/A"}</div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-concord text-sm">Delivery Method :</div>
            <div className="text-black text-sm capitalize">{order?.deliveryType}</div>
          </div>
        </div>
        <div className="space-y-1 w-full sm:w-1/2">
          <div className="flex item-start sm:items-center space-x-2 justify-start sm:justify-end mt-2 lg:mt-0">
            {/* <div className="relative">
              {order?.status?.info && (
                <div className="text-sm sm:text-lg text-black capitalize">
                  Status : <span className="text-green-500">{order?.status?.info}</span>
                </div>
              )}
            </div> */}
            {/* <Button
              buttonLabel={"View Detail"}
              buttonLabelClasses={"!text-sm sm:!text-lg !font-normal capitalize"}
              buttonClasses={"!bg-sv-blue !h-8 sm:!h-12 !px-4 justify-center"}
              buttonHasLink={true}
              buttonLink={`/orderdetails?orderId=${order?.orderId}`}
            /> */}
          </div>
        </div>
      </div>
      <div className="hover:bg-gray-100 px-3 sm:px-7 py-3 sm:py-2 mt-5 overflow-hidden">
        <div className="relative flex lg:items-center">
          <div className="w-full">
            {order?.items?.map((item, index) => (
              <div key={index}>
                {item?.status?.info === "processing" && (
                  <Link to={`/orderdetails?orderId=${order?.orderId}`} className="w-full block">
                    <div className="flex items-center justify-between gap-5 border-b border-slate-100 py-5">
                      <div className="">
                        <div className="flex items-center gap-3">
                          <div className="w-20 md:w-44 h-20 md:h-44 overflow-hidden relative z-[0] flex-shrink-1 md:flex-shrink-0 rounded-lg border border-slate-200">
                            {/* {item?.product?.cover?.url && (
                              <Image
                                src={item?.product?.cover?.url}
                                fill={true}
                                loader={({ src, width, quality }) => {
                                  return `${src}?w=${width}&q=${quality || 75}`;
                                }}
                                priority={true}
                                quality={35}
                                alt={item?.product?.cover?.url}
                                className="w-full h-full object-cover object-top"
                              />
                            )} */}
                          </div>
                          <div className="relative flex-col lg:flex-row flex lg:items-center justify-center lg:justify-between w-full">
                            <div className="space-y-1">
                              <div className="text-base font-semibold text-black line-clamp-1">
                                {item?.product?.name}
                              </div>
                              <div className="flex items-center space-x-1">
                                {item?.product?.attributes?.map((ele) =>
                                  ele?.attribute === "color" ? (
                                    <>
                                      <div className="text-black text-sm capitalize">{ele?.attribute}</div>
                                      <div className="text-black text-sm font-medium">{ele?.items}</div>
                                    </>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                              <div className="flex items-center space-x-1">
                                <div className="text-black text-sm capitalize">Qty:</div>
                                <div className="text-black text-sm font-medium">{item?.quantity}</div>
                              </div>
                              <div className="flex items-center space-x-1">
                                <div className="text-black text-sm capitalize">Total: $</div>
                                <div className="text-black text-sm font-medium">
                                  {(item?.quantity || 0) * (item?.product?.salePrice || 0)}
                                </div>
                              </div>
                              <div className="flex items-center space-x-1">
                                <div className="text-black text-sm capitalize">Reward Points Earned:</div>
                                <div className="text-yellow-500 text-sm font-medium">{item?.rewardPoints}</div>
                              </div>
                              <div className="text-[10px] font-bold text-blue-800 mt-1 flex items-center">
                                <i className="fa-regular fa-fw fa-square-exclamation  mr-1"></i>
                                {item?.rewardPointUsed === false ? (
                                  <span>Not yet credited</span>
                                ) : (
                                  <span className="">Credited to your wallet successfully!</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="flex items-center justify-end gap-5">
                          <div className="relative">
                            {item?.status?.info && (
                              <div className="text-sm sm:text-lg text-black capitalize">
                                Status:{" "}
                                <span className="text-sv-blue">
                                  {item?.status?.info === "processing" ? "Processing" : ""}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="relative space-y-3"></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const OrdersList = () => {
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [list, setList] = useState({
    loading: false,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const loadlist = useCallback(() => {
    setList((pre) => ({ ...pre, loading: true }));
    getordersFrontList({
      itemStatus: ["processing"],
      limit: limit === 0 ? list?.totalItem : limit,
      offset,
      sortQuery: sort,
    }).then((res) => {
      if (res && res.status === 200) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.totalPages,
          totalItem: res?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, loading: false }));
      }
    });

  }, [limit, offset]);

  useEffect(() => {
    loadlist();
  }, [loadlist]);
  // const [visibleOrders, setVisibleOrders] = useState(5);

  // const loadMore = () => {
  //   setVisibleOrders((prevVisibleOrders) => Math.min(prevVisibleOrders + 5, list.length));
  // };

  return (
    <div className="relative space-y-5">
      {list?.data?.map((order, index) => (
        <Order key={index} order={order} setList={setList} list={list} />
      ))}
      {/* {list && visibleOrders < list.length && (
        <div className="text-center">
          <Button
            buttonLabel="Load More"
            buttonClasses="bg-gray-200 text-black py-2 px-4 rounded"
            buttonFunction={loadMore}
          />
        </div>
      )} */}
      {/* {list?.loading && <Skeleton />} */}
    </div>
  );
};

export default OrdersList;

// const Skeleton = ({ lines = 6 }) => {
//   return (
//     <div className="animate-pulse space-y-4 px-4">
//       {Array.from({ length: lines }).map((_, index) => (
//         <div key={index} className="h-5 bg-gray-200 rounded"></div>
//       ))}
//     </div>
//   );
// };
