// import { pictureUpdate } from "@/services/profileService";
// import Image from "next/image";
import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import UserImage from "@/public/assets/image/user.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { getProfile } from "@/redux/slice/profileSlice";

const ProfileImage = () => {
  const [image, setImage] = useState("https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg");

//   const dispatch = useDispatch();
//   const profile = useSelector((state) => state?.profile?.data);

//   useEffect(() => {
//     setImage(profile?.image?.url);
//   }, [profile]);

  const handleImageChange = (e) => {
    // const file = e.target.files[0];
    // const reader = new FileReader();

    // pictureUpdate({ image: file }).then((res) => {
    //   if (res?.status === 200) {
    //     dispatch(getProfile(res?.data?.data));
    //     reader.onload = () => {
    //       setImage(reader.result);
    //     };

    //     if (file) {
    //       reader.readAsDataURL(file);
    //     }
    //     toast.success("Photo updated successfully !");
    //   }
    // });
  };

  return (
    <div className="relative">
      <input type="file" id="upload" accept="image/*" className="hidden" onChange={handleImageChange} />
      <div className="rounded-full overflow-hidden w-24 lg:w-32 h-24 lg:h-32 border-2 border-org-theme relative shadow-md mx-auto lg:ml-0">
        <img
          src={image}
          fill={true}
          loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality || 75}`}
          priority={false}
          quality={35}
          alt={image}
          loading="lazy"
          className="w-full h-full object-cover"
        />
      </div>
      <button
        className="z-2 text-sm w-8 h-8 absolute bottom-[6px] xxs:right-[80px] xs:right-[110px] md:right-[252px] lg:right-[28px] xl:right-[60px] 2xl:right-[81px] bg-org-theme rounded-full p-1 text-white"
        onClick={() => document.getElementById("upload").click()}
      >
        <i className="fa-solid fa-pen" />
      </button>
    </div>
  );
};

export default ProfileImage;
