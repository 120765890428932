import { apiRequest } from "../util/apiUtils";

export const userProfile = async (params) => {
  try {
    const res = await apiRequest("get", `/user/profile`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const userVerifyTokan = async (params) => {
  try {
    const res = await apiRequest("get", "admin/authentication/verifyToken", {});
    return res;
  } catch (error) {
    return error;
  }
};

export const myWishList = async (params) => {
  try {
    const res = await apiRequest("get", "variant/wishlist", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addWishlist = async (params) => {
  try {
    const res = await apiRequest(
      "patch",
      "/variant/wishlist-unwishlist",
      { body: params },
      "application/json"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getAddress = async (params) => {
  try {
    const res = await apiRequest("get", "/address", { queries: params });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addAddress = async (params) => {
  try {
    const res = await apiRequest(
      "patch",
      params?.addressId ? "update-address" : "/add-address",
      {
        body: { ...params },
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const ckeckOutAddress = async (params) => {
  try {
    const res = await apiRequest("get", "/address", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addAddressFront = async (params) => {
  try {
    const res = await apiRequest("patch", "profile/add-address", {
      body: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const newPlaceOrder = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "order/place-order",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

