import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  cartListTotal: {},
};

export const cartTotalSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartDetails: (state, action) => {
      state.loading = false;
      state.cartListTotal = action.payload;
    },
  },
});

export const { setCartDetails } = cartTotalSlice.actions;

export default cartTotalSlice.reducer;

