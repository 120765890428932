import React, { useCallback, useEffect, useState } from "react";
import ProductPreview from "../../components/productDetails/ProductPreview";
import ProductInformation from "../../components/productDetails/ProductInformation";
import SingleProductDescription from "../../components/productDetails/SingleProductDescription";
import RelatedProduct from "../../components/home/RelatedProduct";
import { useLocation, useParams } from "react-router-dom";
import { getProductDetails } from "../../services/productservice";

const ProductDetails = () => {
  const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const color = queryParams.get("color");
  const size = queryParams.get("size");
  const length = queryParams.get("length");

  const [details, setDetails] = useState({ loading: true, data: {} });

  const getDetails = useCallback(() => {
    if (slug) {
      setDetails({ loading: true, data: {} });
      getProductDetails({
        slug: slug,
        color: color || "",
        size: size || "",
        length: length || "",
      }).then((res) => {
        if (res?.status === 200) {
          setDetails({ loading: true, data: res?.data });
        }
      });
    }
  }, [slug, color, size, length]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <>
      <div className="relative py-10 bg-slate-50">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="grid grid-cols-1  lg:grid-cols-2 gap-4 sm:gap-8 lg:gap-12 xl:gap-16">
            <div>
              <ProductPreview data={details}></ProductPreview>
            </div>
            <div>
              <ProductInformation data={details} />
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <SingleProductDescription />
      </div>
      <RelatedProduct></RelatedProduct>
    </>
  );
};

export default ProductDetails;
