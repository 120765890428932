import React, { useEffect, useState } from "react";
import Image from "../elements/Image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const ProductPreview = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isSolid, setIsSolid] = useState(false);
  const [isVerient, setIsVerient] = useState(null);
  const [productDetails, setProductDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data?.data?.product?.type == "variant-product") {
      setIsVerient(true);
      if (data?.data?.product?.variant?._id) {
        setProductDetails(data);
      }
    } else {
      setIsVerient(false);
    }
    setIsSolid(data?.data?.product?.variant?.isWishlisted);
  }, [data]);

  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });

  const mainSlider = [
    data?.data?.product?.variant?.cover,
    ...(data?.data?.product?.variant?.gallery || []),
  ]?.map((item) => ({ image: item?.url }));

  return (
    <div className="relative rounded py-2 lg:py-5">
      <div className="mb-0 lg:mb-20">
        <Swiper
          spaceBetween={5}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          navigation={{
            nextEl: ".shop-button-next",
            prevEl: ".shop-button-prev",
          }}
          className="mainPreview"
        >
          {mainSlider?.map((item, index) => (
            <SwiperSlide key={index}>
              <Link href={item.image} data-fancybox="gallery">
                <div className="w-full aspect-[4/5] overflow-hidden relative rounded-md">
                  {item.image && (
                    <Image
                      src={item.image}
                      quality={35}
                      alt={"item?.name || item?.image"}
                      className="w-full h-full object-contain rounded-md"
                    />
                  )}
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        <button
          type="button"
          className="shop-button-prev border border-slate-400 rounded-md w-10 h-10 hover:bg-black hover:text-white cursor-pointer"
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <button
          type="button"
          className="shop-button-next border border-slate-400 rounded-md w-10 h-10 hover:bg-black hover:text-white cursor-pointer"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>

        <button
          className="flex items-center justify-center absolute w-10 h-10 rounded-full bg-white overflow-hidden right-5 top-7 z-[2] shadow"
          //   onClick={toggleHeart}
        >
          <i
            className={isSolid ? "fa-solid fa-heart" : "fa-regular fa-heart"}
            style={{ color: isSolid ? "#f00" : "#000" }}
          ></i>
        </button>
      </div>

      <div className="relative px-8 my-2">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          navigation={{
            nextEl: ".shop-thumb-button-next",
            prevEl: ".shop-thumb-button-prev",
          }}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="product-preview-thumb"
        >
          {mainSlider?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="w-full h-full rounded-md overflow-hidden border border-slate-100">
                {item?.image && (
                  <Image
                    src={item.image}
                    alt={"item?.name || item.image"}
                    className="w-full h-full object-cover object-top cursor-pointer"
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <button
          type="button"
          className="shop-thumb-button-prev border border-slate-300 rounded-md w-10 h-10 hover:bg-black hover:text-white cursor-pointer"
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <button
          type="button"
          className="shop-thumb-button-next border border-slate-300 rounded-md w-10 h-10 hover:bg-black hover:text-white cursor-pointer"
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ProductPreview;
