
import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addAddressFront } from "../../../services/userService";
import Button from "../../form/Button";
import Input from "../../form/Input";

const AddAddressFront = ({ setSelectedAddress, onClose = () => {}, setAddressList = () => {} }) => {

  const [loading, setLoading] = useState(false);

  // const onSubmit = (values) => {
  //   // setLoading(true);
  //   addAddressFront({
  //     address: {
  //       address1: values?.address1,
  //       address2: values?.address2,
  //       city: values?.city,
  //       state: values?.state,
  //       country: values?.country,
  //       zipcode: parseInt(values?.zipcode),
  //       phone: values?.phone,
  //       altPhone: values?.altPhone,
  //       isDefault: true,
  //     },
  //   }).then((res) => {
  //     if (res?.status === 200) {
  //       setAddressList(res?.docs);
  //       setSelectedAddress(res.docs.find((address) => address?.isDefault));
  //       onClose(true), toast?.success(res?.message);
  //     }
  //   });
  // };
  return (
    <>
      <form >
        {/* <div className="relative w-100 h-auto sm:h-96">
          <div className="space-y-3">
            <div className="grid grid-cols-12 gap-4">
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"Address 1"}
                  inputType={"text"}
                  inputPlaceholder={"Address"}
                  inputName={"address1"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please input address",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"Address 2"}
                  inputType={"text"}
                  inputPlaceholder={"Address"}
                  inputName={"address2"}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"City"}
                  inputType={"text"}
                  inputPlaceholder={"City"}
                  inputName={"city"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please input city",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"State"}
                  inputType={"text"}
                  inputPlaceholder={"State"}
                  inputName={"state"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please input state",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"Country"}
                  inputType={"text"}
                  inputPlaceholder={"Country"}
                  inputName={"country"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please input country",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"ZipCode"}
                  inputType={"number"}
                  inputPlaceholder={"Zipcode"}
                  inputName={"zipcode"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please input zipcode",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"Phone"}
                  inputType={"number"}
                  inputPlaceholder={"Phone"}
                  inputName={"phone"}
                  register={register}
                  errors={errors}
                  rules={{
                    required: false,
                    message: "Please input phone number",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <Input
                  label={"Alternate phone"}
                  inputType={"number"}
                  inputPlaceholder={"Alternate phone"}
                  inputName={"altPhone"}
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-3 mt-4 sm:mt-0">
          <Button
            buttonFunction={onClose}
            buttonIconPosition={"left"}
            buttonIcon={"fa-regular fa-close"}
            buttonLabel={"Cancel"}
            buttonClasses={"!bg-white !text-victoria"}
          />
          <Button
            buttonHasLink={false}
            buttonType={"submit"}
            buttonIcon={"fa-light fa-floppy-disk"}
            buttonIconPosition={"left"}
            buttonLabel={"Save"}
            isDisable={loading}
            loading={loading}
          />
        </div> */}
      </form>
    </>
  );
};

export default AddAddressFront;
