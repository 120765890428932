import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { formValidate } from "../helpers/formValidate";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import Background from "../assets/loginBackground.jpg";
import {
  getRoleCodeList,
  SignInService,
  signupUser,
} from "../services/loginService";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../redux/slice/authSlice";
import { getProfileDetails } from "../services/profilrService";
import { setProfileDetails } from "../redux/slice/profileSlice";

const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
  name: { required: true, message: "Name is required" },
  phone: { required: true, message: "Phone number is required" },
};

const SignUp = () => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const loadList = useCallback(() => {
    getRoleCodeList({ code: "USER" }).then((res) => {
      if (res && res?.status === 200) {
        setRoleList(res?.docs);
      } else {
        setRoleList([]);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      const res = await signupUser({
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        password: values?.password,
        role:
          roleList &&
          Array?.isArray(roleList) &&
          roleList.length > 0 &&
          roleList[0]?._id,
      });

      if (res?.status === 200) {
        toast.info(res?.message);
        navigate("/login", { state: values?.email });
      } else if (res?.status === 400) {
        toast.info(res?.message || res?.errors);
      } else {
        toast.info(res?.message || res?.errors);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
    } finally {
      setLoaded(true);
    }
  };
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-6 px-4 lg:px-8"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md bg-transparent  backdrop-blur-sm border-2 border-victoria py-8 rounded-2xl shadow-lg">
        <div className="flex min-h-full flex-1 flex-col justify-center px-4 py-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto"
              src="https://unicorncloths.com/assets/img/unicorn.svg"
              alt="Your Company"
            />
            <h2 className="mt-2 text-center text-2xl font-extrabold text-white">
              Sign Up
            </h2>
          </div>
          <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-1">
                <Input
                  label="Name"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="text"
                  inputPlaceholder="Enter your name"
                  value={values?.name}
                  inputName="name"
                  onChange={handleChange}
                  {...formValidate(errors, "name")}
                />
              </div>
              <div className="mt-1">
                <Input
                  label="Email"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="text"
                  inputPlaceholder="Enter your email"
                  value={values?.email}
                  inputName="email"
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="mt-1">
                <Input
                  label="Phone"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="text"
                  inputPlaceholder="Enter your phone"
                  value={values?.phone}
                  inputName="phone"
                  onChange={handleChange}
                  {...formValidate(errors, "phone")}
                />
              </div>
              <div className="mt-1">
                <Input
                  label="Password"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="password"
                  inputPlaceholder="Enter your password"
                  value={values?.password}
                  inputName="password"
                  onChange={handleChange}
                  {...formValidate(errors, "password")}
                />
              </div>
              <div className="mt-6">
                <Button
                  buttonClasses="w-full text-white bg-victoria hover:bg-secondaryColor focus:outline-none justify-center rounded-md"
                  buttonHasLink={false}
                  buttonType="submit"
                  buttonLabel="Sign Up"
                  buttonIconPosition="left"
                  buttonIcon="fa-light fa-right-to-bracket"
                />
              </div>
              {/* Uncomment and adjust if needed
            <div className="mt-1 text-end">
              <div className="text-sm">
                <Link
                  to="/forgotPassword"
                  className="font-semibold text-victoria hover:text-secondaryColor"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
