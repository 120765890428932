import React, { useEffect, useState } from "react";
import Tabs from "../../components/elements/Tabs";
import Active from "./tabcontent/Active";
import OrderMenu from "./OrderMenu";
import { getProfileDetails } from "../../services/profilrService";

const MyOrders = () => {
  const tabData = [
    {
      _id: 1,
      name: "Processing",
      component: <Active />,
    },
    {
      _id: 2,
      name: "Shipped",
      // component: <Shipped />,
    },
    {
      _id: 3,
      name: "Delivered",
      // component: <Completed />,
    },
    {
      _id: 4,
      name: "Canceled",
      // component: <Cancelled />,
    },
  ];
  const [data, setData] = useState(null);
  
  useEffect(() => {
    getProfileDetails().then((res) => {
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  return (
    <div>
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={data?.name} />
            </div>
            <div className="w-full relative bg-white p-10">
              <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[85%] after:z-10">
                My Orders
              </div>
              <Tabs
                tabs={tabData}
                divClass={"w-full sm:justify-between"}
                activeClass={"sm:!px-10"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
