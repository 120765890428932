import { toast } from "react-toastify";
import { useState } from "react";
import Image from "../elements/Image";
import Button from "../form/Button";
import { useSelector } from "react-redux";
import { addToCartFront } from "../../services/cartService";
import CartdeleteModal from "../elements/CartDeleteModal";

const WishlistItem = ({
  data,
  closeButton = false,
  setlist = () => {},
  loadlist,
}) => {
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    apiEndpoint: null,
    method: undefined,
    targetId: null,
    data: {},
    payload: {
      body: {
        //body or queries
        ids: null,
      },
    },
  });
  const isLogin = useSelector((state) => state?.auth?.isLogin);

  const getDeleteResponse = (res) => {
    if (res?.data?.status === 200) {
      setlist((pre) => ({
        ...pre,
        data: pre.data?.filter((item) => item?._id !== res?.data?.data?._id),
        totalItem: pre?.totalItem - 1,
      }));
      setDeleteModal({ isOpen: false });

      toast.success("Removed From Wishlist");
    } else {
      toast.error("Not Removed From Wishlist");
    }
  };

  const addToCartFunction = () => {
    addToCartFront({
      productId: data?.productId,
      variantId: data?._id,
      type: data?.type,
      quantity: 1,
    }).then((res) => {
      if (res.status === 200) {
        toast.success(res?.message);
        // dispatch(getCartData([...cartLists, res?.data]));
        loadlist();
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <>
      <div className="relative flex items-center space-x-4 my-3 border-b border-slate-200 pb-4">
        <div className="w-20 md:w-44 h-20 md:h-44 overflow-hidden relative z-[0] flex-shrink-1 md:flex-shrink-0 rounded-lg">
          {data?.cover?.url && (
            <Image
              src={data?.cover?.url}
              fill={true}
              loader={({ src, width, quality }) => {
                return `${src}?w=${width}&q=${quality || 75}`;
              }}
              priority={true}
              quality={35}
              alt={data?.cover?.url}
              className="h-full w-full object-cover object-top"
            />
          )}
        </div>
        <div className="relative flex-col sm:flex-row flex item-start sm:items-center justify-center sm:justify-between w-full">
          <div className="">
            <h3 className="text-base font-bold text-black line-clamp-1">
              {data?.name}
            </h3>
          </div>
          <div className="flex-col sm:flex-row flex item-start sm:items-center space-x-0 sm:space-x-2 flex-shrink-0">
            <h5 className="text-black font-bold text-lg">${data?.salePrice}</h5>
            <div className="flex justify-start">
              {data?.inCart ? (
                <Button
                  buttonLabel={"Go to cart"}
                  buttonClasses={
                    "flex items-center text-sm sm:text-base text-white !h-8 sm:!h-10 px-2 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-victoria bg-victoria shadown-none !bg-sv-blue !px-4 !border-none hover:!bg-black transition-all duration-200"
                  }
                  buttonLabelClasses={
                    "text-sm font-semibold block !leading-none  text-white capitalize whitespace-nowrap !font-normal"
                  }
                  buttonHasLink={true}
                  buttonLink={"/cart"}
                />
              ) : (
                <Button
                  buttonLabel={"Add To Cart"}
                  buttonClasses={
                    "flex items-center text-sm sm:text-base text-white !h-8 sm:!h-10 px-2 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-victoria bg-victoria shadown-none !bg-sv-blue !px-4 !border-none hover:!bg-black transition-all duration-200"
                  }
                  buttonLabelClasses={
                    "text-sm font-semibold block !leading-none  text-white capitalize whitespace-nowrap !font-normal"
                  }
                  buttonFunction={addToCartFunction}
                />
              )}
            </div>

            {closeButton && (
              <Button
                buttonClasses="!bg-transparent !px-0 !border-none !text-black !text-2xl !absolute sm:!relative -top-4 sm:top-0 -right-3 sm:right-0"
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonFunction={() => {
                  setDeleteModal({
                    isOpen: true,
                    apiEndpoint: "variant/wishlist-unwishlist",
                    method: "patch",
                    data: data,
                    payload: {
                      body: {
                        //body or queries
                        id: data._id,
                      },
                    },
                  });
                }}
              />
            )}
            <CartdeleteModal
              customMessage="You're about to take an action. Are you sure? Click 'Delete' or 'Cancel' below to proceed."
              customRecord="Delete Item"
              open={deleteModal?.isOpen}
              data={{
                apiEndpoint: deleteModal?.apiEndpoint,
                method: deleteModal?.method,
                data: deleteModal?.data,
                payload: deleteModal?.payload,
              }}
              onClose={() => setDeleteModal({ isOpen: false })}
              getResponse={getDeleteResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WishlistItem;
