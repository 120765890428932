import { useCallback, useState } from "react";
import { useEffect } from "react";
import AddAddressFront from "./AddAddressFront";
import Modal from "../../elements/modal/Modal";
import Button from "../../form/Button";
import { ckeckOutAddress } from "../../../services/userService";

const Index = ({ title, buttonTitle, billingAddressChange = () => {} }) => {
  const [addressList, setAddressList] = useState({ loading: true, data: [] });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const addressListFn = useCallback(() => {
    setAddressList({ loading: true, data: [] });
    ckeckOutAddress().then((res) => {
      if (res?.status === 200) {
        setAddressList({ loading: false, data: res?.docs || [] });
        const defaultAddress = res.docs.find((address) => address.isDefault);
        if (defaultAddress) {
          setSelectedAddress(defaultAddress);
          billingAddressChange(selectedAddress);
        }
      } else {
        setAddressList({ loading: false, data: [] });
      }
    });
  }, []);

  useEffect(() => {
    addressListFn();
  }, [addressListFn]);

  useEffect(() => {
    billingAddressChange(selectedAddress);
  }, [selectedAddress]);

  const addAddressFunction = () => {
    setOpenModal(true);
  };
  console.log(addressList, "addressListaddressList");
  return (
    <>
      {title && (
        <div className="text-2xl text-slate-900 tracking-wider">{title}</div>
      )}
      <div className="relative flex flex-wrap gap-4">
        {addressList?.data?.map((item, index) => (
          <div
            key={index}
            className={`w-full md:w-[201px] lg:w-[160px] bg-sv-blue/10 cursor-pointer rounded-md shadow-md p-4 mb-4 ${
              item === selectedAddress
                ? "border-2 border-blue-500 !bg-blue-50"
                : ""
            }`}
            onClick={() => {
              setSelectedAddress(item);
              billingAddressChange(item);
            }}
          >
            <div className="text-sm font-medium mb-2">
              {item.address1}, {item.address2}
            </div>
            {item.landmark && (
              <div className="mb-1 text-xs">Landmark: {item.landmark}</div>
            )}
            <div className="mb-1 text-xs">
              {item.city}, {item.state}, {item.country}, {item.zipcode}
            </div>
            {item.phone && <div className="mb-1">Phone: {item.phone}</div>}
            {item.altPhone && (
              <div className="text-sm mb-1">
                Alternate Phone: {item.altPhone}
              </div>
            )}
          </div>
        ))}
      </div>
      {addressList?.loading && <Skeleton />}

      <div className="border border-slate-100 w-full"></div>
      {addressList?.data.length < 2 && (
        <Button
          buttonLabel={`Add a new ${buttonTitle} address`}
          buttonIcon={"fa-regular fa-plus"}
          buttonIconPosition={"left"}
          buttonLabelClasses={"text-lg !font-normal !capitalize"}
          buttonClasses={
            "!bg-sv-blue !h-12 !px-4 !border-0 hover:!bg-black transition-all duration-300"
          }
          buttonHasLink={true}
          buttonLink={"/myaddress"}
          // buttonFunction={addAddressFunction}
        />
      )}

      <Modal open={openModal} size="xl2" title="Add New Address">
        <AddAddressFront
          onClose={() => setOpenModal(false)}
          setAddressList={setAddressList}
          setSelectedAddress={setSelectedAddress}
        />
      </Modal>
    </>
  );
};

export default Index;

const Skeleton = ({ lines = 4 }) => {
  return (
    <div className="animate-pulse space-y-4 px-2">
      {Array.from({ length: lines }).map((_, index) => (
        <div key={index} className="h-6 bg-gray-200 rounded"></div>
      ))}
    </div>
  );
};
