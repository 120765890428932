import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "./modal/Modal";
import Button from "../form/Button";
import { apiRequest } from "../../util/apiUtils";

const CartDeleteModal = ({
  open = false,
  onClose = () => {},
  customMessage = "",
  customRecord = "",
  customIcon = "",
  customButtonLabel = "",
  getResponse = () => {},
  data = {
    apiEndpoint: "",
    method: "delete",
    targetId: null,
    data: {},
    payload: {
      body: {
        //body or queries
        ids: null,
      },
    },
  },
}) => {
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    if (data?.apiEndpoint) {
      try {
        const res = await apiRequest(
          data?.method,
          data?.apiEndpoint + (data?.targetId ? "/" + data?.targetId : ""),
          data?.payload ? data?.payload : {}
        );
        if (res?.status === 200) {
          setLoading(false);
          getResponse({
            ...res,
            data: {
              ...res?.data,
              ...data,
            },
          });
        } else {
          setLoading(false);
          getResponse(res);
        }
        return;
      } catch (error) {
        setLoading(false);
        getResponse(error);
        return;
      }
    } else {
      setLoading(false);
      getResponse();
      return;
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        if (loading) {
          toast.info("Process is running please wait");
        } else {
          onClose();
        }
      }}
      size="sm"
      titleShow={false}
      watermark={false}
    >
      <div className="text-center font-bold text-lg flex gap-2 justify-center text-red-500">
        <span>
          <i
            className={
              customIcon ? customIcon : "fa-regular fa-circle-exclamation"
            }
          ></i>
        </span>
        {customRecord ? customRecord : <>Delete Record</>}
      </div>
      <div className="text-center my-3 text-slate-500">
        {customMessage ? (
          customMessage
        ) : (
          <>
            You are trying to delete the record,
            <br /> click &quot;Delete&quot; below.
          </>
        )}
      </div>
      <div className="flex gap-3 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonClasses={"w-24 justify-center"}
          buttonLabel={"Cancel"}
          buttonFunction={() => {
            if (loading) {
              toast.info("Process is running please wait");
            } else {
              onClose();
            }
          }}
        />
        <Button
          buttonFunction={deleteHandler}
          buttonIconPosition={"left"}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : customIcon
              ? customIcon
              : "fa-regular fa-trash-can"
          }
          buttonClasses={"!bg-red-500 !border-red-500 w-24 justify-center"}
          buttonLabel={customButtonLabel ? customButtonLabel : "Delete"}
        />
      </div>
    </Modal>
  );
};

export default CartDeleteModal;
