import { apiRequest } from "../util/apiUtils";

export const SignInService = async (
    params,
    setLoaded = () => {},
    setResData = () => {}
  ) => {
    setLoaded(false);
    try {
      const res = await apiRequest("post", "user-login", {
        body: {
          email: params?.email,
          password: params?.password,
        },
      });
      const data = res.data;
      setResData(data);
      setLoaded(true);
      return res;
    } catch (error) {
      setResData(error);
      setLoaded(true);
      return error;
    }
  };


  export const userVerifyTokan = async (params) => {
    try {
      const res = await apiRequest("post", "/verify-token", {});
      return res;
    } catch (error) {
      return error;
    }
  };

  export const signupUser = async (payload = {}) => {
    try {
      const res = await apiRequest("post", "signup", {
        body: payload,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  export const verifyOtpUser = async (payload = {}) => {
    try {
      const res = await apiRequest("post", "verify-otp", {
        body: payload,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  export const getRoleCodeList = async (params) => {
    try {
      const res = await apiRequest("get", "/roles", { queries: params });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  
  