import React, { useEffect, useState } from "react";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import {
  changePassword,
  getProfileDetails,
  profileUpdate,
} from "../../services/profilrService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderMenu from "./OrderMenu";
import { useDispatch } from "react-redux";
import { setProfileDetails } from "../../redux/slice/profileSlice";

const ProfileDetails = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getProfileDetails().then((res) => {
      if (res.status === 200) {
        setData(res?.data);
        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
      });
    }
  }, [data]);

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    const { name, phone, email } = formData;
    let validationErrors = {};

    if (!name) {
      validationErrors.name = "Name is required.";
    }

    if (!phone) {
      validationErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(phone)) {
      // Adjust the regex according to your phone number format
      validationErrors.phone = "Phone number must be 10 digits.";
    }

    if (!email) {
      validationErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    return validationErrors;
  };

  const onSubmit = async () => {
    setLoaded(true);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error("Please fix the errors in the form.");
      setLoaded(false);
      return;
    }

    try {
      const res = await profileUpdate({
        name: formData?.name,
        email: formData?.email,
        phone: formData?.phone,
      });

      if (res?.status === 200 || res?.status === 201) {
        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
        toast.success(res?.message);
      } else {
        toast.info(res?.message || res?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Something went wrong!!!");
    }
  };

  return (
    <div>
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={data?.name} />
            </div>
            <div className="w-full relative bg-white p-10">
              <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[85%] after:z-10">
                My Info
              </div>
              <div className="w-full lg:w-4/5">
                <div className="grid grid-cols-12 sm:grid-cols-6 gap-x-1 sm:gap-x-5 gap-y-5">
                  <div className="relative col-span-12 sm:col-span-3">
                    <Input
                      label={"Name*"}
                      inputType={"text"}
                      inputName={"name"}
                      value={formData?.name}
                      labelClasses={"!font-normal capitalize !text-base"}
                      inputClasses={
                        "h-12 !border-none !bg-gray-100 !font-normal "
                      }
                      inputPlaceholder="Enter Your Name"
                      onChange={handleFormData}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>

                  <div className="relative col-span-12 sm:col-span-3">
                    <Input
                      label={"Phone*"}
                      inputType={"text"}
                      inputName={"phone"}
                      value={formData?.phone}
                      labelClasses={"!font-normal capitalize !text-base"}
                      inputClasses={
                        "h-12 !border-none !bg-gray-100 !font-normal "
                      }
                      inputPlaceholder="Phone"
                      onChange={handleFormData}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-sm">{errors.phone}</p>
                    )}
                  </div>

                  <div className="relative col-span-12 sm:col-span-3">
                    <div className="">
                      <div className="mb-2">Email: </div>
                      <div className="h-12 flex items-center justify-between pl-3 rounded-lg !text-base !bg-gray-100 !font-normal">
                        <span>{data && data?.email}</span>
                      </div>
                    </div>
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>

                  <div className="relative col-span-12 sm:col-span-6">
                    <div className="flex items-center gap-5">
                      <Button
                        buttonLabel={"Update"}
                        buttonLabelClasses={
                          "!text-base !font-normal !capitalize"
                        }
                        buttonClasses={
                          "!border-org-theme !text-white !border-0 !h-10 !px-4 hover:!bg-black"
                        }
                        buttonType={"button"}
                        buttonHasLink={false}
                        buttonFunction={onSubmit}
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-x-1 sm:gap-x-5 py-6 space-y-5 sm:space-y-0">
                  <div className="relative col-span-12 sm:col-span-4">
                    <Input
                      label={"Old Password"}
                      inputType={"password"}
                      inputName={"oldPassword"}
                      value={passwordData.oldPassword}
                      labelClasses={"!font-normal capitalize !text-base"}
                      inputClasses={
                        "h-12 !border-none !bg-gray-100 !font-normal "
                      }
                      inputPlaceholder="Old Password"
                      onChange={handlePasswordChange}
                    />
                    {errors.oldPassword && (
                      <p className="text-red-500 text-sm">
                        {errors.oldPassword}
                      </p>
                    )}
                  </div>
                  <div className="relative col-span-12 sm:col-span-4">
                    <Input
                      label={"New Password"}
                      inputType={"password"}
                      inputName={"newPassword"}
                      value={passwordData.newPassword}
                      labelClasses={"!font-normal capitalize !text-base"}
                      inputClasses={
                        "h-12 !border-none !bg-gray-100 !font-normal "
                      }
                      inputPlaceholder="New Password"
                      onChange={handlePasswordChange}
                    />
                    {errors.newPassword && (
                      <p className="text-red-500 text-sm">
                        {errors.newPassword}
                      </p>
                    )}
                  </div>
                  <div className="relative col-span-12 sm:col-span-4">
                    <Input
                      label={"Confirm Password"}
                      inputType={"password"}
                      inputName={"confirmPassword"}
                      value={passwordData.confirmPassword}
                      labelClasses={"!font-normal capitalize !text-base"}
                      inputClasses={
                        "h-12 !border-none !bg-gray-100 !font-normal "
                      }
                      inputPlaceholder="Confirm Password"
                      onChange={handlePasswordChange}
                    />
                    {errors.confirmPassword && (
                      <p className="text-red-500 text-sm">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                  <div className="col-span-12 text-right">
                    <Button
                      buttonLabel={"Change Password"}
                      buttonLabelClasses={"!text-base !font-normal !capitalize"}
                      buttonClasses={
                        "!border-org-theme !text-white !border-0 !h-10 !px-4 hover:!bg-black"
                      }
                      buttonType={"button"}
                      buttonHasLink={false}
                      buttonFunction={onSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
