import React, { useCallback, useEffect, useState } from "react";
import List from "../components/wishlist/List";
import { myWishList } from "../services/userService";
import { useSelector } from "react-redux";
import OrderMenu from "./profile/OrderMenu";
import { getProfileDetails } from "../services/profilrService";

const WishList = () => {
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [data, setData] = useState(null);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [sortQuery, setSortQuery] = useState("ordering");
  const [list, setlist] = useState({
    loading: false,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const loadlist = useCallback(() => {
    setlist((pre) => ({ ...pre, loading: false }));
    myWishList({
      limit: limit === 0 ? list?.totalItem : limit,
      offset,
      sortQuery,
    }).then((res) => {
      if (res && res.status === 200) {
        setlist({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setlist((pre) => ({ ...pre, loading: false }));
      }
    });
  }, []);

  useEffect(() => {
    loadlist();
  }, [loadlist]);

  
  useEffect(() => {
    getProfileDetails().then((res) => {
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  return (
    <div>
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={data?.name} />
            </div>
            <div className="w-full relative bg-white p-10">
              <div className="capitalize relative text-cape-cod text-2xl border-l border-org-theme pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[85%] after:z-10">
                Wishlist
              </div>
              <List list={list} setlist={setlist} loadlist={loadlist} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;
