import React, { useState } from "react";
import Radio from "../../form/Radio";
import BillingDetails from "../billingDetailsSection";
const ShippingAddress = ({
  shippingAddress,
  shippingDescription,
  shippingChange = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState("same");

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <div className="text-2xl text-slate-900 tracking-wider">
        {shippingAddress}
      </div>
      <div className="text-base text-slate-900 ">{shippingDescription}</div>
      <div className="w-full bg-white rounded-xl px-5 sm:px-10 sm:pl-0 py-5 ">
        <div className="relative divide-y divide-slate-200">
          <div className="relative py-3">
            <Radio
              radioLabel={"Same as Billing address"}
              radioLableClass={"!font-bold text-lg"}
              radioName={"Shipping address"}
              divClass={"!flex-row"}
              onChange={() => handleRadioChange("same")}
              value={"same"}
              checked={selectedOption === "same"}
            />
          </div>
          <div className="relative py-3">
            <Radio
              radioLabel={"Use a different shipping address"}
              radioLableClass={"!font-bold text-lg"}
              radioName={"Shipping address"}
              divClass={"!flex-row"}
              onChange={() => handleRadioChange("different")}
              value={"different"}
              checked={selectedOption === "different"}
            />
          </div>
        </div>
        {selectedOption === "different" && (
          <BillingDetails
            buttonTitle={"shipping"}
            billingAddressChange={(val) => shippingChange(val)}
          />
        )}
      </div>
    </>
  );
};

export default ShippingAddress;
