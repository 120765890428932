import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "../../elements/Image";

const Index = ({ orderSummary, itemsData = () => {}, payload }) => {
  const { data: cartLists, isLoaded: cartLoaded } = useSelector(
    (state) => state?.cart?.cartData
  );
  const cartCalculationData = useSelector(
    (state) => state?.cartTotal?.cartListTotal
  );
  const deliveryCharge = 50;
  const totalAmount = cartCalculationData?.totalCartValue + deliveryCharge;

  useEffect(() => {
    const items = cartLists.map((item) => {
      if (item.isFree) {
        return {
          productId: item.product?._id,
          variantId: item.product?.variant?._id,
          quantity: item.quantity,
          isFree: true,
        };
      } else {
        return {
          productId: item.product?._id,
          variantId: item.product?.variant?._id,
          quantity: item.quantity,
          isFree: false,
        };
      }
    });
    itemsData({
      items: items,
      totalPrice: totalAmount.toFixed(2),
    });
  }, [cartLists]);

  const OrderItemData = cartLists?.map((item) => ({
    type: item?.product?.type,
    deliveryMethod: item?.deliveryMethod,
    deliveryDetail: item?.deliveryDetail,
    productname: item.product?.name,
    quantity: item.quantity,
    isFree: item?.isFree,
    brand: item?.product?.brand?.name || "Unicron Cloths",
    image: item.product?.variant?.cover?.url,
    price: item.product?.variant?.salePrice,
    attributes: item.product?.variant?.attributes?.map((attr) => ({
      attributeName: attr?.attribute?.name,
      value: attr?.items?.name,
    })),
  }));

  return (
    <div className="px-4 divide-y divide-slate-200">
      <div className="text-xl text-cape-cod tracking-wider py-2">
        {orderSummary}
      </div>
      {OrderItemData.map((item, index) => (
        <div key={index} className="flex items-center gap-4 py-4">
          <div className="w-20 h-20 rounded overflow-hidden relative z-[0] flex-shrink-0">
            <Image
              src={item?.image}
              fill={true}
              alt={item?.productname}
              className="w-full h-full rounded-lg"
            />
          </div>
          <div className="w-full">
            <div className="w-full flex gap-1 items-center justify-between">
              <h6 className="font-bold text-org-theme">
                {item?.productname.substring(0, 20)}...
              </h6>
              <p className="text-sm tracking-wide font-bold text-gray-600 flex gap-2">
                <span className={`${item?.isFree ? "line-through" : ""}`}>
                  {" "}
                  Rs. {item?.price || 0}
                </span>
              </p>
            </div>
            {
              <div className="flex flex-wrap">
                {item?.attributes &&
                  item?.attributes?.length > 0 &&
                  item.attributes.map((attr, index) => (
                    <p key={index} className="text-xs text-gray-600 mr-1">
                      <span className="text-black font-medium capitalize">
                        {attr?.attributeName}
                      </span>
                      {":"} {attr.value}
                    </p>
                  ))}
              </div>
            }
            {item?.brand && (
              <div className="capitalize text-xs">
                <span className="font-semibold ">Brand :</span> {item?.brand}
              </div>
            )}
            <p className="text-sm text-gray-600">
              Quantity{":"} {item?.quantity}
            </p>
            <p className="text-sm text-gray-600 font-bold">
              Total{":"} Rs.{item?.quantity * item?.price || 0}
            </p>
          </div>
        </div>
      ))}
      {!cartLoaded && <Skeleton />}
      <div className="w-full space-y-2 py-3">
        <div className="flex gap-8 items-center">
          <div className="text-left text-[15px] text-black font-bold  capitalize w-1/2">
            Sub Total
          </div>
          <div className="text-sv-blue font-medium  capitalize w-1/2 text-right">
            Rs.{cartCalculationData?.totalCartValue || 0}
          </div>
        </div>
        {/* <div className="flex justify-between items-center">
            <div className="text-left text-[12px] text-black font-bold  capitalize ">
              Tax({siteSetting?.tax})%
              {giftcardItem?.length > 0 && (
                <span className="text-green-500 font-semibold mt-1 text-[11px]">
                  (Excludes Gift Cards)
                </span>
              )}
            </div>
            <div className="text-sv-blue font-medium  capitalize  text-right">
              + $ {taxAmount.toFixed(2)}
            </div>
          </div> */}

        <div className="flex gap-8 items-center ">
          <div className="text-left text-[15px] text-black font-bold  capitalize w-1/2">
            Shipping Charges
          </div>
          <div className="text-sv-blue font-medium  capitalize w-1/2 text-right">
            + Rs. {Number(50).toFixed(2) || 0}
          </div>
        </div>

        <div className="text-sv-blue font-medium capitalize text-right flex xxs:text-sm xs:text-xl items-center text-2xl">
          <div className="text-left text-black xs:font-normal sm:font-bold xxs:text-xs xs:text-xl capitalize mr-4">
            Total
          </div>
          Rs. {cartCalculationData?.totalCartValue + 50}
        </div>
      </div>
    </div>
  );
};

export default Index;

const Skeleton = ({ lines = 4 }) => {
  return (
    <div className="animate-pulse space-y-4 px-2">
      {Array.from({ length: lines }).map((_, index) => (
        <div key={index} className="h-6 bg-gray-200 rounded"></div>
      ))}
    </div>
  );
};
